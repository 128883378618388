<template>
  <v-container fluid>
    <v-expansion-panels dense small focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header-container">
            <v-icon color="primary">mdi-filter</v-icon>
            <span>Filters</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="my-3">
          <v-row dense>
            <v-col cols="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="options.startDate"
                    label="Start Date"
                    readonly
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="options.startDate"
                  @input="updateRange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    v-model="options.endDate"
                    label="End Date"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="options.endDate"
                  @input="updateRange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-radio-group row dense v-model="options.DocStatus">
                <v-radio label="Open" value="O"></v-radio>
                <v-radio label="Closed" value="C"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="8">
              <v-text-field
                label="Search by separating items using a comma(,)"
                dense
                v-model="options.search"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                small
                :loading="searchLoader"
                color="primary"
                @click="getOpenItems"
                >Apply Filters <v-icon>mdi-card-search</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          v-model="selectedItems"
          dense
          small
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="OpenItems"
          :search="search"
          show-select
          :items-per-page="options.itemsPerPage"
          :options.sync="options"
          :server-items-length="totalItems"
          class="elevation-1"
          :server-search="true"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon variant="primary" @click="editItem(item)">mdi-eye</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">
        <v-autocomplete
          v-show="OpenItems.length > 0"
          outlined
          dense
          v-model="Driver"
          :items="drivers"
          item-text="RlpName"
          item-value="RlpCode"
          label="Driver"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-show="OpenItems.length > 0"
          outlined
          dense
          v-model="Vehicle"
          :items="vehicles"
          item-text="RegistrationNO"
          item-value="id"
          label="Vehicle"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="sendData"
          v-show="OpenItems.length > 0"
          color="accent"
          small
          :loading="loader"
          >Assign Items</v-btn
        >
      </v-col>
    </v-row>
    <snackbar ref="snackbar"></snackbar>
    <show-dialog
      :editedItem="editedItem"
      :dialog="dialog"
      @close="dialog = false"
    ></show-dialog>
  </v-container>
</template>

<script>
import ShowDialog from "../_components/show-dialog.vue";

export default {
  components: {
    ShowDialog,
  },
  data() {
    return {
      search: null,
      searchLoader: false,
      loader: false,
      editedItem: {},
      selectedItems: [],
      Driver: null,
      drivers: [],
      vehicles: [],
      Vehicle: null,
      dialog: false,
      selectedData: null,
      groupByEnabled: false,
      updateRange: null,
      options: {
        rowsPerPageOptions: [10, 20, 30],
        itemsPerPageOptions: [10, 20, 30],
        itemsPerPage: 50,
        page: 1,
        startDate: null,
        endDate: null,
        DocStatus: "O",
        search: null,
      },
      totalItems: 0,
      headers: [
        { text: "#", value: "id" },
        { text: "Doc No.", value: "DocNum" },
        { text: "Bp Code", value: "CardCode" },
        { text: "BP Name", value: "CardName" },
        { text: "Date", value: "DocDate" },
        { text: "Sales Employee", value: "oslp.SlpName" },
        { text: "Items", value: "actions" },
      ],
      OpenItems: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.getOpenItems();
      },
      deep: true,
    },
  },
  methods: {
    defaultDates() {
      if (!this.options.startDate) {
        const today = new Date();
        this.options.startDate = today.toISOString().substr(0, 10);
      }

      if (!this.options.endDate) {
        const today = new Date();
        this.options.endDate = today.toISOString().substr(0, 10);
      }
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      console.log(item);
      this.dialog = true;
    },
    closeDialog() {
      this.editedItem = {};
      this.dialog = false;
    },
    sendData() {
      const self = this;
      let rdr1Data = this.selectedItems;
      const filteredData = rdr1Data.map((element) => {
        element.document_lines = element.document_lines.filter(
          (item) => item.OpenQty > 0
        );
        return element;
      });

      if (filteredData.length < 1) {
        this.$refs.snackbar.show("Please select the orders to assign!", "red");
      } else {
        if (this.Driver == undefined || this.Driver == null) {
          this.$refs.snackbar.show("Please select driver!", "red");
        }
      }
      let url = "/dispatch/documents";
      let data = {
        RlpCode: this.Driver,
        vehicle_id: this.Vehicle,
        ObjType: 211,
        items: filteredData,
      };
      self.loader = true;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          // console.log(res, "r");
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "success");
            self.$router.push("/dispatch/assignments");
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "red");
          self.loader = false;
        });
    },
    getOpenItems() {
      this.selectedItems = [];
      const self = this;
      this.searchLoader = true;
      let url = `/dispatch/documents?ObjType=13`;
      if (this.options.startDate) {
        url += `${url.includes("?") ? "&" : "?"}StartDate=${
          this.options.startDate
        }`;
      }
      if (this.options.endDate) {
        url += `${url.includes("?") ? "&" : "?"}EndDate=${
          this.options.endDate
        }`;
      }
      if (this.options.endDate) {
        url += `${url.includes("?") ? "&" : "?"}EndDate=${
          this.options.endDate
        }`;
      }
      if (this.options.DocStatus) {
        url += `${url.includes("?") ? "&" : "?"}DocStatus=${
          this.options.DocStatus
        }`;
      }
      if (this.options.search) {
        url += `${url.includes("?") ? "&" : "?"}search=${this.options.search}`;
      }

      this.loading = true;
      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.OpenItems = res.ResponseData.data;
          self.options.page = res.ResponseData.current_page;
          self.options.itemsPerPage = res.ResponseData.per_page;
          self.totalItems = res.ResponseData.total;
          this.searchLoader = false;
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
          this.searchLoader = false;
          this.loading = false;
        });
    },
    getDrivers() {
      const self = this;
      this.$store
        .dispatch("get", `/drivers`)
        .then((res) => {
          self.drivers = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getVehicles() {
      const self = this;
      this.$store
        .dispatch("get", `/vehicles`)
        .then((res) => {
          self.vehicles = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getDrivers();
    this.getOpenItems();
    this.defaultDates();
    this.getVehicles();
  },
};
</script>

<style lang="scss" scoped>
</style>